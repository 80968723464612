import { Link } from 'gatsby';
import styled from 'styled-components';

export const LanguageSpan = styled.span`
  display: inline-block;
  padding: 5px 15px;
  text-decoration: none;
`;

export const LanguageLink = styled(Link)`
  display: inline-block;
  padding: 5px 15px;
  text-decoration: none;
  color: #ffcb05;
  border-right: ${(props) => props.$rightBorder && '1px solid #00a7ea'};
  border-left: ${(props) => props.$leftBorder && '1px solid #00a7ea'};
  :hover {
    color: #ffcb05;
  }
`;

export const LanguageSelector = styled.div`
  position: absolute;
  z-index: 9;
  top: 0;
  right: 10px;
  width: 105px;
  padding: 35px 0 0 0;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 870px) {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
  }
`;

export const HeadContainer = styled.div`
  background: #00539f;
`;

export const Head = styled.div`
  padding: 15px 0px;
  text-align: center;
  @media (max-width: 870px) {
    padding: 50px 25px 15px 25px;
  }
`;
export const HeadLogo = styled.img`
  max-width: 300px;
  width: 100%;
  height: auto;
`;
export const LinkStyle = styled.a`
  cursor: pointer;
`;

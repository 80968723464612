import React from 'react';
import * as Styled from './slpHeaderWithTranslationStyles';
import { useLocation } from '@reach/router';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

export default function SlpHeaderWithTranslation({ sectionData, urlLanguage }) {
  const location = useLocation();

  return (
    <>
      <Styled.LanguageSelector>
        {urlLanguage === 'fr' ? (
          <>
            <Styled.LanguageLink
              to={location?.pathname?.replace(urlLanguage, 'en')}
              $rightBorder
            >
              EN
            </Styled.LanguageLink>
            <Styled.LanguageSpan>FR</Styled.LanguageSpan>
          </>
        ) : (
          <>
            <Styled.LanguageSpan>EN</Styled.LanguageSpan>
            <Styled.LanguageLink
              to={location?.pathname?.replace(urlLanguage, 'fr')}
              $leftBorder
            >
              FR
            </Styled.LanguageLink>
          </>
        )}
      </Styled.LanguageSelector>

      <Styled.HeadContainer>
        <Styled.Head>
          <Styled.LinkStyle
            href={
              isExternalUrlHref(sectionData?.path)
                ? sectionData?.path
                : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + sectionData?.path)
            }
            target="_blank"
          >
            <Styled.HeadLogo
              src={sectionData?.image?.file?.url}
              alt={sectionData?.image?.file?.fileName}
              title={sectionData?.image?.file?.fileName}
            />
          </Styled.LinkStyle>
          <div style={{ clear: 'both' }}></div>
        </Styled.Head>
      </Styled.HeadContainer>
    </>
  );
}
